import { put, call, select, take, delay, race } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import { getCustomer } from 'redux/customer/customer.selectors';
import { getToken } from 'redux/app/app.selectors';
import {
  disconnectCustomer,
  customerUpdated,
} from 'redux/customer/customer.actions';
import { triggerModal } from 'redux/modal/modal.actions';
import nanoid from 'nanoid';
import {
  addTransactionToCancel,
  addTransactionToValidate,
} from 'redux/transactionBacklog/transactionBacklog.actions';
import {
  createTransactionDraft,
  cancelTransactionDraft,
  validateTransactionDraft,
  getCustomerFromBadgeNumber,
} from 'services/innovorder';
import simpleLogService from 'services/SimpleLog';
import {
  requestHardwareUpdate,
  hardwareUpdate,
} from 'redux/hardware/hardware.actions';
import { isReady } from 'redux/hardware/hardware.selectors';
import { getBrandId } from 'redux/serviceAccount/serviceAccount.selectors';
import {
  neptingPaymentSequenceEnded,
  startNeptingPayment,
} from 'redux/nepting/nepting.actions';
import {
  getNeptingPaymentResult,
  getNeptingTicket,
} from 'redux/nepting/nepting.selectors';
import printerService from 'services/Printer/printer.service';
import { buildTicket } from 'services/TicketBuilder';
import { startPayment } from './reload.actions';
import { getReloadAmount } from './reload.selectors';
import {
  PaymentAbortedException,
  BrandDataRetrievalFailed,
  TransactionDraftCreationFailed,
  PrinterIsOffline,
  PaymentValidationFailed,
} from './reload.sagas.errors';

const MAXIMUM_RELOAD_AMOUNT = 30000;
export const DELAY_TPE_COMMUNICATION = 60 * 1000;
export const DELAY_NEPTING_SEQUENCE_ENDED = 50 * 1000;
const DELAY_TRANSACTION_VALIDATION_FAILED = 20 * 1000;
const DELAY_TRANSACTION_CREATION_FAILED = 10 * 1000;

function* paymentHandler(): Saga {
  simpleLogService.info('[Saga][reload][paymentHandler] payment flow started');

  // Get and validate the amount before opening the next page
  const amount: ReturnType<typeof getReloadAmount> = yield select(
    getReloadAmount,
  );
  if (amount === undefined) {
    throw new Error('This saga should not be called without amount');
  }
  if (amount > MAXIMUM_RELOAD_AMOUNT) {
    yield put<Action>(
      triggerModal({
        messageId: 'customAmount.amountError',
        ctaId: 'customAmount.amountErrorCta',
      }),
    );
    return;
  }
  yield put<Action>(push('/payment/hardware'));
  const authToken: ReturnType<typeof getToken> = yield select(getToken);
  if (authToken === undefined) {
    throw new Error('This saga should not be called without a token');
  }
  yield put<Action>(requestHardwareUpdate());

  try {
    const { hardwareUpdateTimeout } = yield race({
      requestedHardwareUpdate: take(getType(hardwareUpdate)),
      hardwareUpdateTimeout: delay(DELAY_TPE_COMMUNICATION),
    });

    if (hardwareUpdateTimeout) {
      simpleLogService.info(
        '[Saga][reload][paymentHandler] hardwareUpdateTimeout triggered',
      );
      throw new Error('Timeout triggered...');
    }

    const hardwareStatus = yield select(isReady);

    if (!hardwareStatus) {
      throw new PrinterIsOffline('hardware disconnected');
    }
    const customer: ReturnType<typeof getCustomer> = yield select(getCustomer);
    const brandId: ReturnType<typeof getBrandId> = yield select(getBrandId);
    try {
      if (brandId === undefined) {
        throw new Error(
          'This saga should not be called without ServiceAccount',
        );
      }
    } catch (e) {
      throw new BrandDataRetrievalFailed({});
    }
    const transactionId = nanoid();

    if (customer === null) {
      throw new Error('This saga should not be called without customer');
    }

    let transactionDraft: AsyncReturnType<typeof createTransactionDraft>;
    try {
      transactionDraft = yield call(createTransactionDraft, {
        customerId: customer.customerId,
        idempotencyKey: transactionId,
        amount,
        auth_token: authToken,
      });
    } catch (e) {
      simpleLogService.info(
        '[Saga][reload][paymentHandler] TransactionDraftCreationFailed error',
      );
      throw new TransactionDraftCreationFailed();
    }
    try {
      yield put<Action>(startNeptingPayment());

      yield take(getType(neptingPaymentSequenceEnded));

      const isPayementSuccessfull = yield select(getNeptingPaymentResult);
      if (!isPayementSuccessfull) throw new Error('payment_failed');
    } catch (e) {
      throw new PaymentAbortedException({ transactionDraft });
    }

    const neptingTicket = yield select(getNeptingTicket);

    yield put(push('/payment/waiting'));

    try {
      const ticket = yield call(buildTicket, {
        customerFirstName: customer.firstName,
        customerLastName: customer.lastName,
        tpeTicket: neptingTicket,
        ewalletBalanceAfter: customer.customerBalance + amount,
        ewalletBalanceBefore: customer.customerBalance,
      });
      yield call(printerService.print, ticket);
    } catch (error) {
      Sentry.captureException(error);
    }

    try {
      yield call(
        validateTransactionDraft,
        transactionDraft.transactionDraftId,
        neptingTicket,
        authToken,
      );
    } catch (e) {
      throw new PaymentValidationFailed({
        transactionDraft,
        transactionId,
        paymentTicket: 'TICKET',
      });
    }

    const updatedCustomer: AsyncReturnType<typeof getCustomerFromBadgeNumber> = yield call(
      getCustomerFromBadgeNumber,
      customer.badgeNumber,
      authToken,
    );

    yield put<Action>(customerUpdated(updatedCustomer));

    yield put<Action>(push('/payment/validated'));

    yield delay(3 * 1000);
  } catch (e) {
    Sentry.captureException(e);
    if (e instanceof TransactionDraftCreationFailed) {
      yield put(push('/error/transaction-creation-failed'));
      yield delay(DELAY_TRANSACTION_CREATION_FAILED);
    } else if (e instanceof PaymentAbortedException) {
      try {
        yield call(
          cancelTransactionDraft,
          e.context.transactionDraft.transactionDraftId,
          authToken,
        );
      } catch (error) {
        yield put(
          addTransactionToCancel(
            e.context.transactionDraft.transactionDraftId,
            e.context.transactionDraft.idempotencyKey,
          ),
        );
      }
      yield put<Action>(push('/error/payment'));
      yield delay(5 * 1000);
    } else if (e instanceof PaymentValidationFailed) {
      yield put(push('/error/validation-failed'));
      yield delay(DELAY_TRANSACTION_VALIDATION_FAILED);
      yield put(
        addTransactionToValidate(
          e.context.transactionDraft.transactionDraftId,
          e.context.transactionId,
          e.context.paymentTicket,
        ),
      );
    } else {
      // TODO: Handle missing error cases
      yield put<Action>(push('/error/payment'));
      yield delay(5 * 1000);
    }
  }

  simpleLogService.info('[Saga][reload][paymentHandler] payment flow ended');

  yield put<Action>(disconnectCustomer());
}

export function* watchReloadActions(): Saga {
  while (true) {
    // Always handle one payment at a time
    yield take(getType(startPayment));
    yield call(paymentHandler);
  }
}
