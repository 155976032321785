import { TerminalInfo } from 'services/Nepting/nepting.types';
import { PrinterInfos } from 'services/Printer/printer.types';
import { RootState } from '../store';
import { HardwareState } from './hardware.reducer';

export const getHardware = (state: RootState): HardwareState => state.hardware;

export const getWebBridgeStatus = (state: RootState): boolean | undefined =>
  getHardware(state)?.isWebBridgeConnected;

export const isWebBridgeConnected = (state: RootState): boolean =>
  !!getWebBridgeStatus(state);

export const getTerminalInfo = (state: RootState): TerminalInfo | undefined =>
  getHardware(state)?.neptingTerminalInfo;

export const getPrinterInfo = (
  state: RootState,
): PrinterInfos | null | undefined => getHardware(state)?.printer;

export const isReady = (state: RootState): boolean =>
  !!getWebBridgeStatus(state) && !!getTerminalInfo(state);
