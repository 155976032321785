import { createReducer, ActionType } from 'typesafe-actions';
import { TerminalInfo } from 'services/Nepting/nepting.types';
import { PrinterInfos } from 'services/Printer/printer.types';
import * as hardwareActions from './hardware.actions';

export type HardwareActions = ActionType<typeof hardwareActions>;

const defaultState = null;

export type HardwareState = {
  isWebBridgeConnected: boolean;
  printer: PrinterInfos | null;
  neptingTerminalInfo?: TerminalInfo;
} | null;

const hardwareReducer = createReducer<HardwareState, HardwareActions>(
  defaultState,
)
  .handleAction(
    hardwareActions.hardwareUpdate,
    (
      state,
      { payload: { isWebBridgeConnected, neptingTerminalInfo, printer } },
    ) => ({
      isWebBridgeConnected,
      neptingTerminalInfo,
      printer,
    }),
  )
  .handleAction(hardwareActions.hardwareUpdateFailed, () => null);

export default hardwareReducer;
