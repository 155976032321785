/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { WebBridge, WebBridgeServiceFactory } from 'services/webBridge';
import {
  BRIDGE_MESSAGE,
  MessageResponsePayload,
} from 'services/webBridge/webBridge.types';
import simpleLogService from 'services/SimpleLog';
import { v4 as uuid } from 'uuid';
import { NeptingConfiguration, NeptingTransaction } from './nepting.types';

const SERVICE_NAME = 'NeptingService';

export const DELAY_TPE_COMMUNICATION = 40 * 1000;

type Message<T> = {
  resolve: (value: T) => void;
  reject: (value: unknown) => void;
};

type MessageMap<T> = {
  [key: string]: Message<T>;
};

export type NeptingSuccessfullTransactionResponse = {
  merchantTicket: null;
  merchantLabel: string;
  maskedPan: string;
  merchantTransactionId: string;
  isTestCard: boolean;
  isSignatureRequired: boolean;
  handWrittenTicket: string;
  customerTicket: string;
  initialDebitTransactionId: null;
  cardEndOfValidity: string;
  isLocalMode: boolean;
  cardToken: string;
  dateTime: string;
  authorizationNumber: string;
  authorizationCode: string;
  localTransactionCount: number;
  amount: number;
};

type NeptingResponses = NeptingSuccessfullTransactionResponse | unknown;

export default class NeptingService {
  private webBridge: WebBridge;

  public enabled = false;

  private neptingConfiguration?: NeptingConfiguration;

  private pendingMessages: MessageMap<NeptingResponses> = {};

  public transactionId?: string;

  public initialized: boolean;

  constructor() {
    this.webBridge = WebBridgeServiceFactory.getInstance();
    this.initialized = false;
  }

  /**
   * Promisify event emission using a payload enveloppe containing a messageId.
   *
   * @param {string} message - The event "name".
   * @param {*} payload - The payload of the event.
   * @returns {Promise<*>} - The promise holding the response.
   */
  emitMessage = <T>(
    message: BRIDGE_MESSAGE,
    payload?: NeptingConfiguration | NeptingTransaction,
    optionalMessageId?: string,
  ): Promise<T> =>
    new Promise((resolve, reject) => {
      const messageId: string = optionalMessageId ?? uuid();

      if (this.webBridge && this.webBridge.ready) {
        this.pendingMessages[messageId] = { resolve, reject } as Message<
          NeptingResponses
        >;
        this.webBridge.emitMessage(
          message,
          payload ? { ...payload, messageId } : { messageId },
        );
      } else {
        reject(Error('NEPTING_WEB_BRIDGE_UNAVAILABLE'));
      }
    });

  /**
   * Init webbridge listener to Nepting message responses.
   */
  initWebBridgeListener = () => {
    simpleLogService.info(
      `[NetpingService][initWebBridgeListener] init new WebBridgeListener`,
    );
    // eslint-disable-next-line consistent-return
    const listener = (response: MessageResponsePayload) => {
      if (!response.messageId) {
        return;
      }
      if (this.pendingMessages[response.messageId]) {
        const { resolve, reject } = this.pendingMessages[response.messageId];
        delete this.pendingMessages[response.messageId];

        if (response.error) {
          simpleLogService.info(
            `[NetpingService][pendingMessages][response] error will reject promise`,
          );
          // eslint-disable-next-line consistent-return
          return reject(response.payload);
        }

        // eslint-disable-next-line consistent-return
        return resolve(response.payload);
      }

      // TODO better error handling
      console.error(SERVICE_NAME, 'No pending messages for', response);
      simpleLogService.info(
        `[NetpingService][pendingMessages] No pending messages for`,
        response,
      );
    };

    if (this.webBridge.ready) {
      this.webBridge.on(
        BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE,
        'NEPTING_SERVICE',
        listener,
      );
    }
  };

  /**
   *
   * @param restaurantId
   * @param channelId
   * @returns {Promise<init>}
   */
  // eslint-disable-next-line consistent-return
  async init(merchantId: string) {
    this.initWebBridgeListener();

    return this.initConnection(merchantId).catch(err => {
      console.error(SERVICE_NAME, 'Could not init connection', err);
      throw Error('Could not init connection');
    });
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  async initConnection(merchantId: string) {
    this.initialized = false;

    simpleLogService.info(
      '[NetpingService][initConnection] emitMessage init message',
    );

    const login = await this.emitMessage(
      BRIDGE_MESSAGE.NEPTING_PINPAD_INITIALIZE,
      { merchantId },
    );

    this.initialized = true;
    this.neptingConfiguration = {
      merchantId,
    };

    return login;
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getTerminalInfo() {
    simpleLogService.info(
      '[NetpingService][getTerminalInfo] emit get info message',
    );
    return this.emitMessage(
      BRIDGE_MESSAGE.NEPTING_PINPAD_GET_TERMINAL_INFORMATION,
    );
  }

  /**
   *
   * @param cents
   * @param currency
   * @param paymentMethod
   * @returns {Promise<unknown>}
   */
  startTransaction = ({
    cents,
  }: {
    cents: number;
  }): Promise<NeptingSuccessfullTransactionResponse> => {
    if (this.transactionId) {
      simpleLogService.info(
        '[NetpingService][startTransaction] throw because transactionId is not undefined',
      );
      throw Error('NEPTING_PENDING_TRANSACTION');
    }

    const transactionId = uuid();
    this.startPendingTransactionTimeout(transactionId);

    return this.emitMessage<NeptingSuccessfullTransactionResponse>(
      BRIDGE_MESSAGE.NEPTING_PINPAD_START_TRANSACTION,
      {
        cents,
        currency: 'EUR',
        paymentMethod: 'DEBIT',
        merchantTransactionId: transactionId,
      },
      transactionId,
    ).finally(() => {
      simpleLogService.info(
        `[NetpingService][startTransaction] transaction ${transactionId} ended`,
      );
      this.transactionId = undefined;
    });
  };

  //   /**
  //    *
  //    * @returns {Promise<unknown>}
  //    */
  abortTransaction = () => {
    simpleLogService.info(
      '[NetpingService][abortTransaction] send aborting transaction',
    );
    return this.emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_ABORT_TRANSACTION);
  };

  startPendingTransactionTimeout = (transactionId: string) => {
    this.transactionId = transactionId;

    simpleLogService.info(
      '[NetpingService][startPendingTransactionTimeout] start timeout',
    );

    setTimeout(() => {
      if (this.pendingMessages[transactionId]) {
        console.error('Rejecting nepting transaction ', this.transactionId);
        simpleLogService.info(
          'timeout : Rejecting nepting transaction ',
          this.transactionId,
        );
        this.abortTransaction();
        const { reject } = this.pendingMessages[transactionId];
        reject('operation timed out');
        delete this.pendingMessages[transactionId];
      }
      this.transactionId = undefined;
    }, DELAY_TPE_COMMUNICATION);
  };

  /**
   *
   * @returns {boolean}
   */
  isEnabled() {
    return !!this.enabled;
  }

  /**
   *
   * @returns {boolean}
   */
  isInitialized() {
    return !!this.initialized;
  }
}

export const NeptingServiceFactory = (function() {
  let instance: NeptingService;
  return {
    getInstance() {
      if (instance == null) {
        instance = new NeptingService();
      }
      return instance;
    },
  };
})();
