import { createAction } from 'typesafe-actions';
import { TerminalInfo } from 'services/Nepting/nepting.types';
import { PrinterInfos } from 'services/Printer/printer.types';

export const hardwareUpdate = createAction(
  'HARDWARE_UPDATE',
  (
    isWebBridgeConnected: boolean,
    printer: PrinterInfos | null,
    neptingTerminalInfo?: TerminalInfo,
  ) => ({
    isWebBridgeConnected,
    neptingTerminalInfo,
    printer,
  }),
)();

export const hardwareUpdateFailed = createAction('HARDWARE_UPDATE_FAILED')();
export const requestHardwareUpdate = createAction('REQUEST_HARDWARE_UPDATE')();
