import { fork, delay, call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { WebBridgeServiceFactory } from 'services/webBridge/index';
import { NeptingServiceFactory } from 'services/Nepting/nepting.service';
import { getNeptingMerchantId } from 'redux/configuration/configuration.selectors';
import printerService from 'services/Printer/printer.service';
import {
  hardwareUpdate,
  hardwareUpdateFailed,
  requestHardwareUpdate,
} from './hardware.actions';

const HARDWARE_UPDATE_POLLING_INTERVAL = 1000 * 60 * 5;
const HARDWARE_UPDATE_ERROR_POLLING_INTERVAL = 1000 * 4;

function* updateHarwarehandler(): Saga {
  while (true) {
    try {
      const bridge = WebBridgeServiceFactory.getInstance();

      // eslint-disable-next-line no-unused-vars
      yield call(bridge.searchConnection);

      const neptingMerchantId: ReturnType<typeof getNeptingMerchantId> = yield select(
        getNeptingMerchantId,
      );

      const isWebBridgeConnected = !!bridge.ready;

      if (isWebBridgeConnected) {
        if (neptingMerchantId) {
          const neptingService = NeptingServiceFactory.getInstance();

          if (!neptingService.initialized) {
            try {
              yield call(() => neptingService.init(neptingMerchantId));
              const neptingTerminalInfo = yield call(() =>
                neptingService.getTerminalInfo(),
              );
              const printer = yield printerService.searchUSBPrinter();

              yield put(
                hardwareUpdate(
                  isWebBridgeConnected,
                  printer,
                  neptingTerminalInfo,
                ),
              );
            } catch (error) {
              yield put(hardwareUpdate(isWebBridgeConnected, null));
            }
          } else {
            const neptingTerminalInfo = yield call(() =>
              neptingService.getTerminalInfo(),
            );

            const printer = yield printerService.searchUSBPrinter();

            yield put(
              hardwareUpdate(
                isWebBridgeConnected,
                printer,
                neptingTerminalInfo,
              ),
            );
          }
        } else {
          yield put(hardwareUpdate(isWebBridgeConnected, null));
        }
      } else {
        throw new Error('Not connected');
      }
      yield delay(HARDWARE_UPDATE_POLLING_INTERVAL);
    } catch (e) {
      yield put(hardwareUpdateFailed());
      yield delay(HARDWARE_UPDATE_ERROR_POLLING_INTERVAL);
    }
  }
}

export function* watchHardwareActions(): Saga {
  yield fork(updateHarwarehandler);
  yield takeLatest(getType(requestHardwareUpdate), updateHarwarehandler);
}
